import { Route, Router, Switch } from 'wouter'

import { AdminRoute } from './features/auth/AdminRoute'

import { AdminPage } from './features/admin/AdminPage'

import { PrivateMatch } from './features/auth/PrivateMatch'
import { CLIInstallPage } from './features/cli/CLIInstallPage'
import { IntegrationsPage } from './features/integrations/IntegrationsPage'
import { ErrorPage } from './components/layout/ErrorPage'
import { MinimalLayout } from './components/layout/MinimalLayout'
import { WorkspaceProjectsPage } from './features/workspace-projects/WorkspaceProjectsPage'
import { BuildPage } from './features/project-builds/BuildPage'
import { TargetPage } from './features/target/TargetPage'
import { WorkspaceRunsPage } from './features/workspace-runs/WorkspaceRunsPage'
import { RunDefectsReportPage } from './features/run-code-defects/RunDefectsReportPage'
import { RunPage } from './features/run/RunPage'
import { UserSettingsPage } from './features/user/UserSettingsPage'

import { useQuery } from './hooks'
import { Base64Encoded } from './util/location'
import { cleanInteger } from './util/numbers'
import { WorkspaceLayout } from './features/workspace/WorkspaceLayout'
import { WorkspaceDefaultRedirect } from './features/workspace/WorkspaceDefaultRedirect'
import { CreateWorkspacePage } from './features/workspace/CreateWorkspacePage'
import { DefectPage } from './features/defect/DefectPage'

import { CodeRunCreatePage } from './features/run-code-create/CodeRunCreatePage'
import { CreateApiRunPage } from './features/workspace/CreateApiRunPage'
import { WorkspaceDashboardPage } from './features/workspace-dashboard/WorkspaceDashboardPage'
import { JoinWorkspace } from './features/invites/JoinWorkspace'
import { WorkspaceNewProjectPage } from './features/workspace-dashboard-new-project/WorkspaceNewProjectPage'
import { ErrorBoundary } from './components/ErrorBoundary'
import { RedirectToUserWorkspace } from './features/workspace/RedirectToUserWorkspace'
import { WorkspaceSettingsPage } from './features/workspace-settings/WorkspaceSettingsPage'
import { TeamPage } from './features/team/TeamPage'
import { PlanPage } from './features/plan/PlanPage'
import { TestCaseReportPage } from './features/run-code-testcasemodal/TestCaseReportPage'
import { EndpointDetailsPage } from './features/run-api-endpoints/EndpointDetailsPage'
import { DsbomWorkspacePage } from './features/dsbom/DsbomWorkspacePage'
import { DsbomGettingStartedPage } from './features/dsbom/DsbomGettingStartedPage'
import { DsbomComparisonPage } from './features/dsbom-comparison/DsbomComparisonPage'
import { DsbomImagePage } from './features/dsbom-images/DsbomImagePage'
import { DsbomReportPage } from './features/dsbom-reports/DsbomReportPage'
import { DsbomDefectPage } from './features/dsbom-defects/DsbomDefectPage'
import { DsbomPackagesPage } from './features/dsbom-packages/DsbomPackagesPage'
import { ApiDocs } from './features/docs/ApiDocs'
import { ProjectDefectsPage } from './features/project/ProjectDefectsPage'
import { ProjectRunsPage } from './features/project/ProjectRunsPage'
import { ProjectBuildsPage } from './features/project/ProjectBuildsPage'
import { ProjectSettingsWebhooksPage } from './features/project-webhooks/ProjectSettingsWebhooksPage'
import { AccessControlPage } from './features/project-accesscontrol/AccessControlPage'
import { ProjectSettingsGeneralPage } from './features/project-settings/ProjectSettingsGeneralPage'
import { ProjectOverviewPage } from './features/project-overview/ProjectOverviewPage'
import { ProjectRegistriesPage } from './features/project-registries/ProjectRegistriesPage'
import { WithDsbomToken } from './features/dsbom/WithDsbomToken'

// The below lines seem silly, but this is used to rebase a web image
// to an arbitrary base route after building, by simply running
//  find build -type f | xargs -I {} sed -i "s#fake-base-web-route#/my/new/route#"  {}
let BASE_WEB_ROUTE = 'fake-base-web-route'
if (BASE_WEB_ROUTE.startsWith('fake-base-web-rout')) {
  BASE_WEB_ROUTE = ''
}

export function App() {
  const query = useQuery()
  const invalidUrl = query.get('invalidUrl') || false

  return (
    <span>
      <ErrorBoundary>
        <Router base={BASE_WEB_ROUTE}>
          <Switch>
            {!invalidUrl && (
              <Switch>
                <Route path="/-/api-docs" component={ApiDocs} />
                <PrivateMatch path="/-/create-code-run">
                  <RedirectToUserWorkspace path="/-/create-code-run" />
                </PrivateMatch>
                <PrivateMatch path="/-/create-api-run">
                  <RedirectToUserWorkspace path="/-/create-api-run" />
                </PrivateMatch>

                {/* Pages for user-centric views (don't have a workspace in the URL path), still embedded in a workspace */}
                <PrivateMatch path="/-/settings/*?">
                  <WorkspaceLayout>
                    <ErrorBoundary>
                      <Switch>
                        <Route path="/-/settings/user" component={UserSettingsPage} />
                        <Route path="/-/settings/user/personal-info" component={UserSettingsPage} />
                        <Route path="/-/settings/user/api-tokens" component={UserSettingsPage} />
                        <Route path="/-/settings/user/workspaces" component={UserSettingsPage} />
                        <Route path="/-/settings/create-workspace" component={CreateWorkspacePage} />
                        <Route path="/-/settings/join-workspace" component={JoinWorkspace} />
                        <AdminRoute fallbackPath="/-/settings/user">
                          <Switch>
                            <Route path="/-/settings/admin" component={AdminPage} />
                            <Route path="/-/settings/admin/webhooks" component={AdminPage} />
                            <Route path="/-/settings/admin/reports" component={AdminPage} />
                            <Route path="/-/settings/admin/users" component={AdminPage} />
                            <Route path="/-/settings/admin/workspaces" component={AdminPage} />
                            <Route path="/-/settings/admin/integrations" component={AdminPage} />
                            <Route path="/-/settings/admin/registries" component={AdminPage} />
                            <Route path="/-/settings/admin/runs" component={AdminPage} />
                            <Route path="/-/settings/admin/workers" component={AdminPage} />
                          </Switch>
                        </AdminRoute>
                        <Route>
                          <ErrorPage goBackLocation="/-/settings/user" />
                        </Route>
                      </Switch>
                    </ErrorBoundary>
                  </WorkspaceLayout>
                </PrivateMatch>
                <PrivateMatch path="/-/installation">
                  <WorkspaceLayout>
                    <ErrorBoundary>
                      <Switch>
                        <Route path="/-/installation" component={CLIInstallPage} />
                        <Route>
                          <ErrorPage goBackLocation="/" />
                        </Route>
                      </Switch>
                    </ErrorBoundary>
                  </WorkspaceLayout>
                </PrivateMatch>

                {/* Pages without the sidenav (also existing outside of a workspace) */}
                <Route path="/-/*?">
                  <MinimalLayout>
                    <Switch>
                      <Route>
                        <ErrorPage goBackLocation="/" />
                      </Route>
                    </Switch>
                  </MinimalLayout>
                </Route>

                {/* Pages with the Workspace sidenav  */}
                <PrivateMatch path="/">
                  <WorkspaceDefaultRedirect />
                </PrivateMatch>
                <PrivateMatch path="/:workspaceSlug/*?">
                  <WorkspaceLayout>
                    <ErrorBoundary>
                      <Switch>
                        <Route path="/:workspaceSlug">{(params) => <WorkspaceDashboardPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/new-project">{(params) => <WorkspaceNewProjectPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/create-code-run">{(params) => <CodeRunCreatePage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/create-api-run">{(params) => <CreateApiRunPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/projects">{(params) => <WorkspaceProjectsPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/runs">{(params) => <WorkspaceRunsPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/dynamic-sbom/*?">
                          <WithDsbomToken>
                            <Switch>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/start">{(params) => <DsbomGettingStartedPage {...params} />}</Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/compare">{(params) => <DsbomComparisonPage {...params} />}</Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/:imageRepoId/-/defects/:defectId">
                                {(params) => <DsbomDefectPage {...params} />}
                              </Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/-/*?">{(params) => <DsbomWorkspacePage {...params} />}</Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom">{(params) => <DsbomWorkspacePage {...params} />}</Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/packages">{(params) => <DsbomPackagesPage {...params} />}</Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/:imageRepoId/-/*?">{(params) => <DsbomImagePage {...params} />}</Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/:imageRepoId">{(params) => <DsbomImagePage {...params} />}</Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/:imageRepoId/:reportId/-/*?">
                                {(params) => <DsbomReportPage {...params} />}
                              </Route>
                              <Route path="/:workspaceSlug/-/dynamic-sbom/:imageRepoId/:reportId">
                                {(params) => <DsbomReportPage {...params} />}
                              </Route>
                            </Switch>
                          </WithDsbomToken>
                        </Route>
                        <Route path="/:workspaceSlug/-/settings/*?">{(params) => <WorkspaceSettingsPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/plan">{(params) => <PlanPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/integrations">{(params) => <IntegrationsPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/-/teams/:team">{(params) => <TeamPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/-/defects">{(params) => <ProjectDefectsPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/-/runs">{(params) => <ProjectRunsPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/-/builds">{(params) => <ProjectBuildsPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/-/settings/general">{(params) => <ProjectSettingsGeneralPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/-/settings/access-control">{(params) => <AccessControlPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/-/settings/webhooks">
                          {(params) => <ProjectSettingsWebhooksPage {...params} />}
                        </Route>
                        <Route path="/:workspaceSlug/:projectSlug/-/settings/registries">{(params) => <ProjectRegistriesPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug">{(params) => <ProjectOverviewPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/-/builds/:ciUrl">
                          {(params: { workspaceSlug: string; projectSlug: string; ciUrl: Base64Encoded }) => <BuildPage {...params} />}
                        </Route>

                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug">{(params) => <TargetPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug/-/settings/:anything">{(params) => <TargetPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug/-/defects/:defect/runs/:run">
                          {({ run, ...rest }) => <DefectPage runNumber={cleanInteger(run)} {...rest} />}
                        </Route>
                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug/-/defects/:defect">
                          {(params) => <DefectPage runNumber={null} {...params} />}
                        </Route>

                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug/:run/defects">{(params) => <RunDefectsReportPage {...params} />}</Route>
                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug/:run/testcases/:testcaseSha">
                          {({ run, ...rest }) => <TestCaseReportPage runNumber={cleanInteger(run)} {...rest} />}
                        </Route>
                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug/:runNumber/endpoint/:endpointId/tests">
                          {({ runNumber, ...rest }) => <EndpointDetailsPage runNumber={cleanInteger(runNumber)} {...rest} />}
                        </Route>
                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug/:runNumber/endpoint/:endpointId">
                          {({ runNumber, ...rest }) => <EndpointDetailsPage runNumber={cleanInteger(runNumber)} {...rest} />}
                        </Route>
                        <Route path="/:workspaceSlug/:projectSlug/:targetSlug/:run/*?">
                          {({ run, ...rest }) => <RunPage runNumber={cleanInteger(run)} {...rest} />}
                        </Route>
                        <Route path="/:workspaceSlug/*?">{({ workspaceSlug }) => <ErrorPage goBackLocation={`/${workspaceSlug}`} />}</Route>
                      </Switch>
                    </ErrorBoundary>
                  </WorkspaceLayout>
                </PrivateMatch>

                <Route>
                  <ErrorPage goBackLocation="/" />
                </Route>
              </Switch>
            )}
            <PrivateMatch>
              <ErrorPage goBackLocation="/" />
            </PrivateMatch>
          </Switch>
        </Router>
      </ErrorBoundary>
    </span>
  )
}
